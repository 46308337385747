// CallButton.js
import React from 'react';
import { FaPhone } from 'react-icons/fa'; // Importing phone icon from react-icons
import "../styles/Callbutton.css"

const CallButton = () => {
  return (
    <a href="tel:+1 888 811 0201" className="call-button">
      <FaPhone />
    </a>
  );
}

export default CallButton;
